// import './style.css';
import './App.css';

import Allroutes from './Pages/Allroutes';

function App() {
 
  
 return (
<div className="App">
<Allroutes/>
    
      
  </div>   
  );
}

export default App;
